<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <el-table-column align="center"  prop="id" label="兑换券名称"></el-table-column>
        <el-table-column align="center"  prop="spName" label="兑换券名称"></el-table-column>
        <el-table-column align="center"  prop="data" label="时间"></el-table-column>
        <el-table-column align="center"  prop="lqName" label="领取人名称"></el-table-column>
        <el-table-column align="center"  prop="status" label="状态"></el-table-column>
        <el-table-column align="center"  prop="shName" label="审核人名称"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
               <el-tooltip v-if="scope.row.status==1" class="item" effect="dark"  content="同意领券兑换券" placement="top-start">
            <el-button icon="el-icon-edit" type="danger" @click="couponWrite(scope.row.id)" ></el-button>
          </el-tooltip>
           <el-tooltip v-else class="item" effect="dark"   content="同意领券兑换券" placement="top-start">
            <el-button icon="el-icon-edit" disabled type="info" @click="couponWrite(scope.row.id)" ></el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
        <!-- 兑换券提示框 -->
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <div class="handleName"><span>操作人:</span><span>周鸿运</span></div>
  <div><span>是否同意该用户领取兑换券?</span></div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="refuse">拒 绝</el-button>
    <el-button type="primary" @click="consent">同 意</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostUnsubscribe,PostOperate} from "@/api/Firm/write.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //兑换券模态框
      dialogVisible:false,
      dialogFalimyID:'',
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      //家庭Id
      familyId:"",//家庭ID
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
     //打开兑换券模态框
    couponWrite(id){
       console.log(id,"同意领券兑换券");
       this.dialogFalimyID=id
       this.dialogVisible=true
    },
          handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //拒绝领兑换券
      refuse(){
        var that=this;
        var data={
          id:this.dialogFalimyID,
          status:2
         }
          this.$confirm('确认拒绝？')
          .then(_ => {
              console.log("确认拒绝");
              that.dialogVisible=false;
        //            let res=PostOperate(data);
        //         res.then((res)=>{
        //              if(res.data.code==1){
        //    this.tableData=[]
        //           var data={
        //       page:this.getInfo.page_code,
        //       list_num:this.getInfo.page_num
        //     }
        //       this.PostUnsubscribe(data);
        //       this.$message({
        //       message: res.data.message,
        //       type: 'success'
        //     });
        //  }else{
        //      this.$message.error(res.data.message);
        //  }
        //         }).catch((err)=>{
        //             console.log(err);
        //         })
        //          that.dialogVisible=false;
          })
          .catch(_ => {});
      },
      //同意领兑换券
      consent(){
        var that=this;
         var data={
          id:this.dialogFalimyID,
          status:1
         }
           this.$confirm('确认领取？')
          .then(_ => {
              console.log("确认领取");
              that.dialogVisible=false;
        //            let res=PostOperate(data);
        //         res.then((res)=>{
        //              if(res.data.code==1){
        //    this.tableData=[]
        //           var data={
        //       page:this.getInfo.page_code,
        //       list_num:this.getInfo.page_num
        //     }
        //     this.PostUnsubscribe(data);
        //       this.$message({
        //       message: res.data.message,
        //       type: 'success'
        //     });
        //  }else{
        //      this.$message.error(res.data.message);
        //  }
        //         }).catch((err)=>{
        //             console.log(err);
        //         })
        //          that.dialogVisible=false;
          })
          .catch(_ => {});
        
      },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
      
    }
    console.log(data);
    this.tableData=[]
    this.PostUnsubscribe(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.tableData=[]
    this.PostUnsubscribe(data);
    },
    /**-----------------网络请求生命周期函数--------------------- */
     //家庭注销接口
    async PostOperate(data){
      console.log(data);
         let res=await PostOperate(data);
         console.log(res);
         if(res.data.code==1){
           this.tableData=[]
                  var data={
              page:this.getInfo.page_code,
              list_num:this.getInfo.page_num
            }
            this.PostUnsubscribe(data);
              this.$message({
              message: res.data.message,
              type: 'success'
            });
         }else{
             this.$message.error(res.data.message);
         }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.tableData=[{
        id:1,
        spName:'可乐',
        data:'2022-05-31',
        lqName:'彭于晏',
        status:1,
        shName:'周鸿运',
    }]
    // this.PostUnsubscribe(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.handleName{
  padding: 20px 0;
}
</style>